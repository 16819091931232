import React, { useRef, useEffect } from 'react';

const GNavWeb = ({ home, navigate }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if(elementRef && elementRef.current){
      elementRef.current.addEventListener('navigate', () =>{
        navigate();
      })
    }
  }, [navigate]);

  return (
    <g-nav-web home={home} active="3" logo="'https://www.scottwittrock.com/assets/img/banner.png'" ref={elementRef}>
      <a href="https://www.scottwittrock.com/portfolio" style={{ boxShadow: 'none' }}>Portfolio</a>
      <a href="https://www.scottwittrock.com/talks" style={{ boxShadow: 'none' }}>Talks</a>
      <a href="https://www.scottwittrock.com/pages/about" style={{ boxShadow: 'none' }}>About</a>
      <a href="/" style={{ boxShadow: 'none' }}>Blog</a>
    </g-nav-web>
  )
};

export default GNavWeb;