import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import GNavWeb  from "./g-nav-web";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header   

  const nav = () => {
    window.location.href = 'https://www.scottwittrock.com'
  }
  
  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }

  return (
    <div>
      <GNavWeb home="Scott Wittrock" navigate={nav}></GNavWeb>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          width: '680px',
          maxWidth: 'calc(100vw - 32px)',
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
      </div>
      <g-portfolio-footer></g-portfolio-footer>
    </div >
  )
}

export default Layout
