import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography({
  // headerFontFamily: ['sans-serif'],
  // bodyFontFamily: ['medium-content-serif-font', 'Georgia, Cambria', "Times New Roman", 'Times', 'serif'],
  // baseFontSize: '21px',
  // overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
  //   'a': {
  //     boxShadow: 'none', 
  //     textDecoration: 'none'
  //   }
  // })
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
